import React from 'react'
import ImageWrap from 'components/ImageWrap'

export default function GridSimpleIcon({ content }) {
  const { items } = content
  return (
    <section className='grid-simple-icon multivariate-grid multivariate-grid--vertical-clip simple-tile-grid simple-tile-grid--compact'>
      <div className='simple-tile-grid__container multivariate-grid__container'>
        <div className='simple-tile-grid__row multivariate-grid__row'>
          {items.map((item, i) => (
            <GridSimpleIconItem key={i} content={item} />
          ))}
        </div>
      </div>
    </section>
  )
}

const GridSimpleIconItem = ({ content }) => {
  const { title, icon } = content
  return (
    <div className='simple-tile-grid__column multivariate-grid__column'>
      <div className='content-cta-image-card'>
        <div className='content-cta-image-card__content'>
          <div className='figure figure--hide'>
            <ImageWrap image={icon} />
          </div>
          <h4 className='content-cta-image-card__title'>{title}</h4>
        </div>
        <div className='content-cta-image-card__image-cta-container'>
          <div className='figure'>
            <ImageWrap image={icon} />
          </div>
        </div>
      </div>
    </div>
  )
}
