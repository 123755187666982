import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import TitleCopy2 from 'components/TitleCopy2'
import ImageWrap from 'components/ImageWrap'
import TextCubes from 'components/TextCubes'
import GridSimpleIcon from 'components/GridSimpleIcon'
import GridSimple from 'components/GridSimple'
import BannerMid from 'components/BannerMid'
import { strings } from 'js/strings'

export default function CareersTemplate({ data }) {
  const page = data.wpPage
  const {
    titleCopy,
    threeCardLayout,
    titleCopy2,
    textCubes,
    gridSimpleIcon,
    titleCopy3,
    gridSimple,
    bannerMidCareers,
    textCubes2,
  } = page.template.careers

  return (
    <Layout
      bodyClassName='page-template page-template-page-builder page-builder page page-id-129 careers app-data index-data singular-data page-data page-129-data page-careers-data page-builder-data'
      page={page}
    >
      <TitleCopy2 content={titleCopy} />
      <ThreeCardLayout content={threeCardLayout} />
      <TitleCopy2 content={titleCopy2} />
      <TextCubes content={textCubes} addPlaceholder={true} />
      <GridSimpleIcon content={gridSimpleIcon} />
      <TitleCopy2 content={titleCopy3} />
      <GridSimple content={gridSimple} />
      <BannerMid content={bannerMidCareers} />
      <TextCubes content={textCubes2} isCareers={true} addPlaceholder={true} />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      title
      ...SeoPage
      ...BannerPage
      ...RelatedBlockPage
      ...ContactBlockPage
      template {
        templateName
        ... on WpTemplate_Careers {
          careers {
            titleCopy {
              pretitle
              title
              body
            }
            threeCardLayout {
              one {
                image {
                  ...GatsbyImage
                }
              }
              two {
                title
                excerpt
              }
              three {
                image {
                  ...GatsbyImage
                }
              }
            }
            titleCopy2 {
              pretitle
              title
              body
            }
            textCubes {
              title
              body
            }
            gridSimpleIcon {
              items {
                title
                icon {
                  ...GatsbyImage
                }
              }
            }
            titleCopy3 {
              pretitle
              title
              body
            }
            gridSimple {
              title
              items {
                title
                body
              }
            }
            bannerMidCareers {
              image {
                desktop {
                  ...GatsbyImage
                }
                mobile {
                  ...GatsbyImage
                }
              }
              icon {
                ...GatsbyImage
              }
              body
              pageLink {
                ...PageLinkPageInner
                ...PageLinkPostInner
              }
              pageLinkText
            }
            textCubes2 {
              title
              body
            }
          }
        }
      }
    }
  }
`

const ThreeCardLayout = ({ content }) => {
  const { one, two, three } = content
  return (
    <section className='three-card-layout'>
      <div className='three-card-layout__wrap'>
        <article className='card '>
          <div className='card__wrap'>
            <ImageWrap image={one.image} />
          </div>
        </article>

        <div className='three-card-layout__flex'>
          <div className='three-card-layout__left-content'>
            <article className='card  card--copy '>
              <div className='card__wrap'>
                <div className='card__hypercube'></div>
                <h4 className='card__title'>{two.title}</h4>
                <p className='card__excerpt'>{two.excerpt}</p>
                <a
                  href='https://jobs.lever.co/analyticpartners'
                  target='_blank'
                  className='card__button'
                >
                  {strings.viewOpenPositions}
                </a>
              </div>
            </article>
          </div>

          <div className='three-card-layout__right-content'>
            <article className='card '>
              <div className='card__wrap'>
                <ImageWrap image={three.image} />
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}
